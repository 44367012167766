<template>
  <div>
    <div
      class="
        uk-section uk-padding-remove-vertical uk-margin-remove-vertical
        in-equity-breadcrumb
      "
    >
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li>
                <router-link :to="{ name: 'guest.home' }">Home</router-link>
              </li>
              <li><span>Academy</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <template v-if="! user.has_completed_first_assessment">
      <unsubscribed />
    </template>
    <template v-if="user.has_completed_first_assessment">
      <subscribed />
    </template>
  </div>
</template>

<script>
import Unsubscribed from './components/Unsubscribed.vue'
import Subscribed from './components/Subscribed.vue'

export default {
  components: {
    Unsubscribed, Subscribed
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser
    }
  }
}
</script>