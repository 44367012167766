<template>
  <div>
    <div class="uk-section uk-padding-remove-vertical uk-margin-medium-top">
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1 in-card-16">
            <div class="uk-card uk-card-default uk-card-body uk-border-rounded">
              <div class="uk-grid uk-flex-middle" data-uk-grid="">
                <div class="uk-width-1-1 uk-width-expand@m uk-first-column">
                  <h3>
                    Join the community.
                  </h3>
                </div>
                <div class="uk-width-auto">
                  <router-link
                    class="uk-button uk-button-primary uk-border-rounded"
                    :to="{ name: 'guest.community' }"
                    >View Membership Plans<i
                      class="fas fa-arrow-circle-right uk-margin-small-left"
                    ></i
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-container" v-if="details.week == null">
      <div
        class="
          uk-grid
          uk-child-width-expand
          uk-margin-medium-top
          uk-background-default
          uk-padding
        "
      >
        <div class="uk-alert-primary" uk-alert>
          <p>
            There are is no more content at this time. Please try again later.
          </p>
        </div>
      </div>
    </div>

    <div class="uk-container" v-if="details.week != null">
      <div
        class="
          uk-grid
          uk-child-width-expand
          uk-margin-medium-top
          uk-background-default
          uk-padding
        "
      >
        <div v-if="! loading">
          <video-thumbnail :video="week" />
        </div>
        <div>
          <p class="uk-margin-remove uk-text-meta">
            Section {{ details.section.sort }}: {{ details.section.title }}.
          </p>
          <p class="uk-margin-small uk-text-meta">
            Course {{ details.course.sort }}: {{ details.course.title }}
          </p>
          <h3 class="uk-margin-remove">
            Week {{ details.week.sort }}: {{ details.week.title }}
          </h3>
          <p class="uk-margin-small" v-html="details.week.about"></p>
          <h2 class="uk-margin-small" v-if=" ! details.week.paid">
            {{ details.week.cost > 0 ? `$ ${details.week.cost}` : "Free" }}
          </h2>
          <router-link
            :to="{ name: 'guest.academy.pay', params: { id: details.week.id } }"
            class="uk-button uk-button-primary"
            >{{ details.week.paid ? 'Continue' : 'Start'}}</router-link
          >
        </div>
      </div>
    </div>

    <div class="uk-section" v-if="completed_weeks.length > 0">
      <div class="uk-container">
        <h4>Completed Weeks - {{ completed_weeks.length }}/{{ total_weeks }}</h4>
        <div class="uk-grid" data-uk-grid="">
          <div
            class="uk-width-1-5@m uk-first-column"
            v-for="(week, i) in completed_weeks"
            :key="`completed-week-${i}`"
          >
            <div class="uk-card uk-card-default">
              <div class="uk-card-media-top">
                <router-link
                  :to="{ name: 'guest.academy.load', params: { id: week.id } }"
                >
                  <video-thumbnail :video="week" />
                </router-link>
              </div>
              <div class="uk-padding-small">
                <router-link
                  :to="{ name: 'guest.academy.load', params: { id: week.id } }"
                  ><h5>{{ week.title }}</h5></router-link
                >
                <router-link :to="{ name: 'guest.academy.load', params: { id: week.id }}" class="uk-button uk-button-primary" style="width: 100%;margin-top: 10px;">Re-watch</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-section">
      <div class="uk-container">
        <div class="uk-grid" data-uk-grid="">
          <div class="uk-width-3-5@m uk-first-column">
            <h1 class="uk-margin-remove">
              TRADING IS A <span class="in-highlight">BUSINESS</span>
            </h1>
            <h4 class="uk-margin-small-top">YOU NEED TO BE PROPERLY EDUCATED TO BE ABLE TO MAKE CONSISTENT PROFITS</h4>
            <p class="uk-text-muted">
              Trading is not easy, and that is why I do not sell short and useless courses. I am offering a full-blown price action trading education. In my opinion, knowing proper technical analysis constitutes only about 20-30% of long-term trading success. The rest 70-80% are made up of crucial trading aspects such as trading psychology, risk management, money management, development of the strict personal algorithm, personal trading statistics, correct understanding of "value of money," correct mindset, correct expectations, correct understanding of trading in general and so on.
            </p>
            <p class="uk-text-muted">
              Only then do dry technical and fundamental market analyses. Sounds complicated, I know. But don't worry, all you need is a little bit of patience and my guidance. I will guide you through each of these crucial steps to trading success!
            </p>
            <span class="uk-label uk-border-rounded"
              ><i
                class="fa fa-book fa-sm uk-margin-small-right uk-visible@s"
              ></i
              >Learn</span
            >
            <i
              class="
                fa fa-arrow-right fa-sm
                uk-margin-small-left uk-margin-small-right
              "
            ></i>
            <span class="uk-label uk-border-rounded"
              ><i
                class="
                  fas
                  fa-lightbulb fa-sm
                  uk-margin-small-right uk-visible@s
                "
              ></i
              >Understand</span
            >
            <i
              class="
                fas
                fa-arrow-right fa-sm
                uk-margin-small-left uk-margin-small-right
              "
            ></i>
            <span class="uk-label uk-border-rounded"
              ><i
                class="
                  fas
                  fa-chart-area fa-sm
                  uk-margin-small-right uk-visible@s
                "
              ></i
              >Trade</span
            >
          </div>
          <div class="uk-width-2-5@m">
            <div class="uk-card uk-card-default uk-card-body uk-border-rounded">
              <div class="uk-grid uk-grid-small">
                <div class="uk-width-expand@m">
                  <h3 class="uk-margin-remove-bottom">MyForexGlobal Academy</h3>
                  <p class="uk-margin-small-top">
                    The courses are structured in a progressive manner, with
                    several weeks of learning material available.
                  </p>
                </div>
                <div class="uk-width-auto@m uk-visible@m">
                  <div class="in-icon-wrapper transparent uk-margin-top">
                    <i class="fas fa-graduation-cap fa-5x"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      details: { section: {}, course: {}, week: {} },
      loading: true,
      weeks: 0,
      completed_weeks: [],
      total_weeks: ''
    };
  },

  methods: {
    fetch() {
      this.loading = true;
      this.$axios.get("/api/v1/guest/academy").then((response) => {
        this.details = response.data.details;
        this.completed_weeks = response.data.completed_weeks;
        this.total_weeks = response.data.total_weeks;
        this.loading = false

        document.getElementById('completed-weeks-header').innerHTML = `Completed ${this.completed_weeks.length} / ${this.total_weeks} weeks`
      });
    },
  },
};
</script>